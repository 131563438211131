/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          e.preventDefault();
        });


        $(window).scroll(function() {
          if ($(window).scrollTop() > 100) {
            $(".header-bar").addClass("scrolled");

          } else {
            $(".header-bar").removeClass("scrolled");
          }
        });

        $(".glink").on('click', function(){

            $('.glink').removeClass("active");
            $(this).addClass("active");
        });


        var scrolled = false;
          
        $(window).scroll(function() {

          if ($(window).scrollTop() > 350 && !scrolled) {
            

              $({numberValue: 1}).animate({numberValue: 800}, {
                  duration: 5400,
                  easing: 'swing',
                  step: function() { 
                      $('#nbr1').text(Math.ceil(this.numberValue)); 
                  }
              });


              $({numberValue: 0.00}).animate({numberValue: 1.22}, {
                  duration: 5400,
                  easing: 'swing',
                  step: function() { 
                      $('#nbr2').text(this.numberValue.toFixed(2)); 
                  }
              });

              $({numberValue: 1}).animate({numberValue: 21}, {
                  duration: 5400,
                  easing: 'linear',
                  step: function() { 
                      $('#nbr3').text(Math.ceil(this.numberValue)); 
                  }
              });

              scrolled = true;

          } 
        });



         $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function(event) {
              // On-page links
              if ( location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                  // Only prevent default if animation is actually gonna happen
                  event.preventDefault();
                  $('html, body').animate({
                    scrollTop: target.offset().top
                  }, 1000, function() {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                      return false;
                    } else {
                      $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                      $target.focus(); // Set focus again
                    }
                  });
                }
              }
            });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $("#clientCarousel").slick({
            centerMode: true,
            infinite: true,
            centerPadding: '60px',
            arrows: true,
            slidesToShow: 4,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        $("#project-quote").change(function(){
          $(".project-quote-container").slideToggle(500);
        });


        $("#not-sure-checkbox").change(function(){
          if ($(".checkbox-active").attr('disabled')){ $(".checkbox-active").removeAttr('disabled'); } else { $(".checkbox-active").attr("disabled", "disabled"); }
        });

        $("#first-name").keyup(function(){

          var firstName = $("#first-name").val();


          $("#nameField").text(firstName);

        });


        $("#formContact").click(function(e){

          var validator = $( "#contactForm" ).validate();

          e.preventDefault();

           if(validator.form()){

              $("#contactForm").submit();


           }
           else {

                   var errorItem = $(".error:first-of-type"); 

                  $(".error:first-of-type").focus();

                  $("#nextReport").attr("href", "#");

                  $('html, body').animate({
                            scrollTop: errorItem.offset().top
                          }, 1000);


                  return false;
           }


        });


      }
    },
    // About us page, note the change from about-us to about_us.
    'website_audit': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function(){

        $("#nextReport").click(function(e){

            var formURL = $("#auditForm").attr("action");
            var validator = $( "#auditForm" ).validate();


            if(validator.form()){

                    $("#nextReport").attr("href", "#report");
                    $("#report").slideDown(200);


                    $.ajax({

                       url: formURL,
                       method: "POST",
                       data: $("#auditForm").serialize(),
                       success: function(response){
                          
                          console.log(response);

  

                          $("#sendAudit").attr("href", response);

                          $("#oneStep").removeClass("animate-flicker");
                          $("#firstIcon").addClass("icon-active");
                          $("#iconOne").removeClass("fa-spinner");
                          $("#iconOne").addClass("fa-check");
                          $("#oneText").text("Google Pagespeed Report Complete.");
                          $("#twoStep").removeClass("disabled-text");
                          $("#twoStep").addClass("animate-flicker");
                          $("#secondIcon").removeClass("icon-disabled");
                       
                          setTimeout(function(){

                              $("#twoStep").removeClass("animate-flicker");
                              $("#secondIcon").addClass("icon-active");
                              $("#iconTwo").removeClass("fa-spinner");
                              $("#iconTwo").addClass("fa-check");
                              $("#twoText").text("YSlow Performance Report Complete.");

                                $("#threeStep").removeClass("disabled-text");
                                $("#threeStep").addClass("animate-flicker");
                                $("#thirdIcon").removeClass("icon-disabled");

                            }, 3000);



                            setTimeout(function(){

                              $("#threeStep").removeClass("animate-flicker");
                              $("#thirdIcon").addClass("icon-active");
                              $("#iconThree").removeClass("fa-spinner");
                              $("#iconThree").addClass("fa-check");
                              $("#threeText").text("Resource Audit Report Complete.");


                              $("#sendAudit").removeClass("disabled");
                              $("#sendAudit").text("View Audit Report");
                 
                              $("#reportHeading").html("<span class='fa fa-check-circle text-primary'></span> WooHoo! Audit Report Complete!");


                            }, 6000);
                       }

                    });


                    


              }
              else {
                  var errorItem = $(".error:first-of-type"); 

                  $(".error:first-of-type").focus();

                  $("#nextReport").attr("href", "#");

                  $('html, body').animate({
                            scrollTop: errorItem.offset().top
                          }, 1000);

                  

              }


          });


      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
